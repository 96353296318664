const colors = {
  green: {
    '50': '#b5d2b4',
    '100': '#96b695',
    '200': '#7b9e79',
    '300': '#619a46',
    '400': '#528f38',
    '500': '#438029',
    '600': '#3a7422',
    '700': '#2b6014',
    '800': '#255610',
    '900': '#1d490a',
  },
  gold: {
    '100': '#e1c158',
    '200': '#d4af37',
    '300': '#b29700',
  },
  blue: {
    '50': '#b7f4f7',
    '100': '#6eccd9',
    '200': '#89bacd',
    '300': '#11a7c4',
    '400': '#0d8ca5',
    '500': '#0b8299',
    '600': '#166d96',
    '700': '#0e527b',
    '800': '#063351',
    '900': '#14283e',
  },
  red: {
    '50': '#fac4dc',
    '100': '#f297c0',
    '200': '#f7828d',
    '300': '#ed6572',
    '400': '#ff6663',
    '500': '#e54c38',
    '600': '#c23a22',
    '700': '#af3533',
    '800': '#a81800',
    '900': '#8b1704',
  },
  orange: {
    '50': '#fce5dd',
    '100': '#fcd2c2',
    '200': '#fbbda4',
    '300': '#fda987',
    '400': '#fe9369',
    '500': '#fc733d',
    '600': '#f95d20',
    '700': '#dc5125',
    '800': '#bb3827',
    '900': '#741b12',
  },
  gray: {
    '50': '#F7FAFC', // <-
    '100': '#EDF2F7',
    '200': '#E2E8F0',
    '300': '#CBD5E0',
    '400': '#A0AEC0',
    '500': '#718096',
    '600': '#4A5568',
    '700': '#2D3748',
    '800': '#1A202C',
    '900': '#171923',
  },
  black: '#020a13',
  white: '#ffffff',
  blackAlpha: {
    '50': 'RGBA(16, 17, 18, 0.04)',
    '100': 'RGBA(16, 17, 18, 0.06)',
    '200': 'RGBA(16, 17, 18, 0.08)',
    '300': 'RGBA(16, 17, 18, 0.16)',
    '400': 'RGBA(16, 17, 18, 0.24)',
    '500': 'RGBA(16, 17, 18, 0.36)',
    '600': 'RGBA(16, 17, 18, 0.48)',
    '700': 'RGBA(16, 17, 18, 0.64)',
    '800': 'RGBA(16, 17, 18, 0.80)',
    '900': 'RGBA(16, 17, 18, 0.92)',
  },
  github: '#171923',
  telegram: '#2775CA',
  linkedin: '#1564BA',
  discord: '#9747FF',
  slack: '#1BA27A',
  twitter: '#000000',
  opensea: '#2081E2',
  facebook: '#4460A0',
  medium: '#231F20',
  reddit: '#FF4500',
};

export default colors;
